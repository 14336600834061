import React from "react";

export const ProductCard = ({
  cardTitle,
  products,
  locale,
  activated,
  handleCheckbox,
  selectedState,
  setSelectedState,
  selectedFreeWsTitle,
  handleRadio,
}) => {
  const isChecked = selectedState !== null; // 打勾了

  if (products.length === 0) {
    return (
      <div className="card p-4 text-center">
        <h6>{cardTitle}</h6>
        <p className="text-success mt-4">
          <em>Purchased</em>
          <i className="fas fa-check ms-2"></i>
        </p>
      </div>
    );
  }

  if (products.every((p) => p.soldOut)) {
    return (
      <div className="card p-4 text-center">
        <h6>{cardTitle}</h6>
        <p className="text-danger mt-4">
          <em>Sold Out</em>
        </p>
      </div>
    );
  }

  return (
    <div className={isChecked ? "activate card p-4" : "deactivate card p-4"}>
      <div className="cardCheckbox">
        <input
          disabled={!activated}
          className={`form-check-input ${isChecked ? "d-block" : "d-none"}`}
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckbox}
        />
      </div>
      <div style={{ opacity: isChecked ? 1 : 0.9 }}>
        <h6>{cardTitle}</h6>

        {products.map(
          ({ id, title, price, soldOut, buyerType, productName }) => {
            const isDoubleRoom =
              productName.includes("Double") || productName.includes("双人");

            return (
              <div className="form-check" key={id}>
                <input
                  disabled={!activated || soldOut}
                  className="form-check-input"
                  name={cardTitle}
                  type="radio"
                  checked={selectedState == id}
                  id={id}
                  onChange={() => {
                    if (handleRadio) {
                      handleRadio();
                    }
                    setSelectedState(id);
                  }}
                />
                <label
                  className={`form-check-label ${
                    soldOut && "text-decoration-line-through"
                  }`}
                  htmlFor={id}
                >
                  <small>{title === "virtual" ? buyerType : productName}</small>

                  <br />

                  {locale === "cn" ? (
                    <strong className="text-danger">
                      {/* currency symbol + (remove symbol & price to number / 2) */}
                      {isDoubleRoom
                        ? `单人 ${price[0]}${
                            parseFloat(price.substring(1)) / 2
                          }.00 ( 双人一共 ${price}.00 )`
                        : `${price}.00`}
                    </strong>
                  ) : (
                    <strong className="text-danger">
                      {isDoubleRoom
                        ? `${price[0]}${
                            parseFloat(price.substring(1)) / 2
                          }.00 per pax ( Total ${price}.00 )`
                        : `${price}.00`}
                    </strong>
                  )}

                  <em>{soldOut ? " (SOLD OUT)" : ""}</em>
                </label>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
