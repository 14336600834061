import axios from "axios";
import React, { useEffect, useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  locale = "en",
  userId,
  sst,
  token,
  url,
  doubleRoomUrl,
  malaysian,
  purchasedConf,
  purchasedPretour,
  conf,
  pretour,
  doubleRoomIds,
}) => {
  // const [selected, setSelected] = useState(null)
  const [selectedPretour, setSelectedPretour] = useState(null);
  const [selectedConf, setSelectedConf] = useState(null);

  const noSelection = selectedPretour == null && selectedConf == null;
  const selectedDoubleRoom = doubleRoomIds.includes(parseInt(selectedConf));

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const pid = urlParams.get("pid");
  //   if (pid) {
  //     setSelectedConf(pid);
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log(selectedConf, doubleRoomIds, selectedDoubleRoom);
  //   if (selectedDoubleRoom) {
  //     document.querySelector("#guestForm form").action =
  //       doubleRoomUrl + `?pid=${selectedConf}`;
  //     document.getElementById("guestForm").classList.remove("d-none");
  //     document.getElementById("product_id").value = selectedConf;
  //   } else {
  //     document.getElementById("guestForm").classList.add("d-none");
  //   }
  // }, [selectedConf]);

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selectedPretour, selectedConf]}
        />

        {malaysian && (
          <ProductCard
            cardTitle={
              locale == "cn" ? "两天研学旅游体验" : "Pre-Conference Tour"
            }
            products={purchasedPretour ? [] : pretour}
            selectedState={selectedPretour}
            setSelectedState={setSelectedPretour}
            activated={!purchasedPretour}
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedPretour(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        <ProductCard
          cardTitle={
            locale == "cn" ? "两天大会入场券" : "2 Days Conference Pass"
          }
          products={purchasedConf ? [] : conf}
          locale={locale}
          selectedState={selectedConf}
          setSelectedState={setSelectedConf}
          activated={!purchasedConf}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedConf(null);
            }
          }}
          handleRadio={() => {}}
        />

        {sst && (
          <strong className="d-block mx-auto">
            {locale == "cn"
              ? "所有购买均需缴纳8%消费税"
              : "All purchases will incur 8% SST"}
          </strong>
        )}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={noSelection}
        >
          {locale == "cn" ? "购买入场券" : "Purchase Pass"}
        </button>

        {/* {noSelection || selectedDoubleRoom || (
          <button
            type="submit"
            className="btn text-white btn-primary d-block mx-auto mt-4"
            disabled={noSelection}
          >
            {locale == "cn" ? "购买入场券" : "Purchase Pass"}
          </button>
        )} */}
      </form>

      <div className="productDesc">
        {noSelection && (
          <div className="text-muted text-center h-100 d-flex justify-content-center align-items-center pb-4">
            {locale == "cn"
              ? "您尚未选择任何入场券"
              : "You have not selected any ticket"}
          </div>
        )}

        {[selectedPretour, selectedConf].map((id) => {
          if (id == null) {
            return null;
          }

          const product =
            pretour.find((p) => p.id == id) || conf.find((p) => p.id == id);
          return (
            <div
              className="mb-3 px-3 py-2 border"
              style={{ borderColor: "#c7c7c7" }}
              key={id}
            >
              <h6 className="text-danger">{product.productName}</h6>
              <div dangerouslySetInnerHTML={{ __html: product.desc }}></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductSelection;
